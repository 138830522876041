<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="onClickAsignar">
        Asignar
      </b-button>
      <b-button variant="outline-primary" @click="onClickCrear">Nueva</b-button>
    </b-button-group>
    <b-modal @ok="asignarCategoria" :title="titleAssign" v-model="showModalAsignar" ok-title="Asignar" centered
      cancel-title="Cancelar" cancel-variant="outline-secondary" no-close-on-backdrop>
      <v-select v-model="selected" label="nombre" :options="subCategorias" />
    </b-modal>
    <b-modal @ok="crearCategoria" :title="titleCreate" v-model="showModalCrear" @cancel="clearForm"
      :ok-title="id === 0 ? 'Crear' : 'Editar'" centered cancel-title="Cancelar" cancel-variant="outline-secondary"
      no-close-on-esc hide-header-close no-close-on-backdrop>
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-form-checkbox v-model="activa" switch inline>
            Activa
          </b-form-checkbox>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="vi-nombre">
            <b-input-group class="input-group-merge">
              <b-form-input id="vi-nombre" v-model="nombre" placeholder="Nombre" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-1">
          <modal-imagenes-categorias :categoria-selected="imagenes" />
        </b-col>
        <b-col cols="12">
          <b-form-group v-if="tipo === 'MARKET'">
            <b-form-checkbox switch inline v-model="showMain">
              Mostrar en el Home Principal
            </b-form-checkbox>
          </b-form-group>

          <b-form-group v-if="tipo === 'MARKET'">
            <b-form-checkbox switch inline v-model="showDestacadas">
              Mostrar en categorias destacadas
            </b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox switch inline v-model="showHome">
              Mostrar en el Home de {{ tipoComercio }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox switch inline v-model="showCategorias">
              Mostrar en la pantalla Categorias
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BSpinner,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BButtonGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalImagenesCategorias from "./ModalImagenesCategorias.vue";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    vSelect,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BButtonGroup,
    BFormCheckbox,
    ToastificationContent,
    ModalImagenesCategorias,
  },
  props: {
    tipo: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    categoria: {
      type: Object,
      required: true,
    },
    showLoader: {
      type: Function,
      required: true,
    },
    tipoComercio: {
      type: String,
      required: true,
    },
    subCategorias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: 0,
      nombre: "",
      activa: false,
      imagenes: {
        id: 0,
        icono: "",
        imagen: "",
        banner: "",
      },
      selected: null,
      showHome: false,
      showMain: false,
      showCategorias: false,
      showDestacadas: false,
      showModalCrear: false,
      showModalAsignar: false,
    };
  },
  methods: {
    editar(cat) {
      this.id = parseInt(cat.id);
      this.padreId = parseInt(cat.padre_id);
      this.nombre = cat.nombre;
      this.activa = cat.estado === "A";
      this.showHome = cat.tipo.includes("H");
      this.showMain = cat.tipo.includes("M");
      this.imagenes = {
        id: cat.id,
        icono: cat.icono,
        imagen: cat.imagen,
        banner: cat.banner,
      };
      this.showCategorias = cat.tipo.includes("C");
      this.showDestacadas = cat.tipo.includes("D");
      this.showModalCrear = true;
    },
    clearForm() {
      setTimeout(() => {
        this.id = 0;
        this.nombre = "";
        this.activa = false;
        this.showHome = false;
        this.showMain = false;
        this.imagenes = {
          id: 0,
          icono: "",
          imagen: "",
          banner: "",
        };
        this.showCategorias = false;
        this.showDestacadas = false;
      }, 500);
    },
    onClickCrear(e) {
      e && e.preventDefault();
      this.showModalCrear = true;
    },
    onClickAsignar(e) {
      e && e.preventDefault();
      this.showModalAsignar = true;
    },
    crearCategoria() {
      this.showModalCrear = false;
      setTimeout(() => {
        this.createAssign();
      }, 300);
    },
    asignarCategoria() {
      this.showModalAsignar = false;
      setTimeout(() => {
        this.addAssign(this.categoria.id, this.selected.id);
      }, 300);
    },
    createAssign() {
      const categoria = {
        id: this.id,
        tipo: this.generateType,
        nombre: this.nombre,
        activa: this.activa,
        idPadre: this.id === 0 ? Number(this.categoria.id) : this.padreId,
        lineaDeNegocio: this.tipo,
      };
      this.showLoader(true);
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategory",
          body: JSON.stringify({
            empresa: 2,
            categoria,
            tokenSesion: getAuthToken(),
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            this.clearForm();
            this.showLoader(false);
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: this.id === 0
                    ? "Se creó correctamente la sub categoría."
                    : "Se actualizo correctamente la sub categoria.",
                  title: "Éxito",
                  variant: "success",
                },
              });
              return;
            }
          }
          throw new Error(res.data.mensaje);
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader(false);
        });
    },
    addAssign(parentID, childID) {
      this.showLoader(true);
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Categoria/CategoriaPadresAdmin",
          body: JSON.stringify({
            opt: "I",
            IDEmpresa: 2,
            IDCategoria: parentID,
            tokenSesion: getAuthToken(),
            IDCategoriaHija: childID,
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            this.showLoader(false);
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se actualizo correctamente la categoria.",
                  title: "Éxito",
                  variant: "success",
                },
              });
              return;
            }
          }
          throw new Error(res.data.mensaje);
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader(false);
        });
    },
  },
  computed: {
    generateType() {
      let tipo = "";
      if (this.showHome) tipo += "H";
      if (this.showMain) tipo += "M";
      if (this.showCategorias) tipo += "C";
      if (this.showDestacadas) tipo += "D";
      if (tipo.length === 0) tipo += "N";
      return tipo;
    },
    titleCreate() {
      if (this.id === 0)
        return `Crear subcategoria de [${this.categoria.nombre}]`;
      else return `Editar subcategoria`;
    },
    titleAssign() {
      return `Asignar subcategoria a [${this.categoria.nombre}]`;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
