var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.onClickAsignar
    }
  }, [_vm._v(" Asignar ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.onClickCrear
    }
  }, [_vm._v("Nueva")])], 1), _c('b-modal', {
    attrs: {
      "title": _vm.titleAssign,
      "ok-title": "Asignar",
      "centered": "",
      "cancel-title": "Cancelar",
      "cancel-variant": "outline-secondary",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.asignarCategoria
    },
    model: {
      value: _vm.showModalAsignar,
      callback: function callback($$v) {
        _vm.showModalAsignar = $$v;
      },
      expression: "showModalAsignar"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "nombre",
      "options": _vm.subCategorias
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('b-modal', {
    attrs: {
      "title": _vm.titleCreate,
      "ok-title": _vm.id === 0 ? 'Crear' : 'Editar',
      "centered": "",
      "cancel-title": "Cancelar",
      "cancel-variant": "outline-secondary",
      "no-close-on-esc": "",
      "hide-header-close": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.crearCategoria,
      "cancel": _vm.clearForm
    },
    model: {
      value: _vm.showModalCrear,
      callback: function callback($$v) {
        _vm.showModalCrear = $$v;
      },
      expression: "showModalCrear"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.activa,
      callback: function callback($$v) {
        _vm.activa = $$v;
      },
      expression: "activa"
    }
  }, [_vm._v(" Activa ")])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.nombre,
      callback: function callback($$v) {
        _vm.nombre = $$v;
      },
      expression: "nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('modal-imagenes-categorias', {
    attrs: {
      "categoria-selected": _vm.imagenes
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.tipo === 'MARKET' ? _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.showMain,
      callback: function callback($$v) {
        _vm.showMain = $$v;
      },
      expression: "showMain"
    }
  }, [_vm._v(" Mostrar en el Home Principal ")])], 1) : _vm._e(), _vm.tipo === 'MARKET' ? _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.showDestacadas,
      callback: function callback($$v) {
        _vm.showDestacadas = $$v;
      },
      expression: "showDestacadas"
    }
  }, [_vm._v(" Mostrar en categorias destacadas ")])], 1) : _vm._e(), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.showHome,
      callback: function callback($$v) {
        _vm.showHome = $$v;
      },
      expression: "showHome"
    }
  }, [_vm._v(" Mostrar en el Home de " + _vm._s(_vm.tipoComercio) + " ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.showCategorias,
      callback: function callback($$v) {
        _vm.showCategorias = $$v;
      },
      expression: "showCategorias"
    }
  }, [_vm._v(" Mostrar en la pantalla Categorias ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }