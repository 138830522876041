<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Categorías de tiendas</b-card-title>
          <b-button variant="primary" @click="createCategory" :disabled="loading">
            Crear
          </b-button>
        </b-card-header>
        <b-card-body>
          <b-tabs @input="onChangeTab" pills>
            <b-tab title="Market" active>
              <categorias-screen comercio="MARKET" :loading="loading" :categoria="categoriaSelected"
                :sub-categoria="subCategoriaSelected" :categorias-data="categoriasMarket"
                @onclick-category="setCategorySelected" @onclick-sub-category="setSubCategorySelected"
                :sub-categorias="categoriasHijas" />
            </b-tab>
            <b-tab title="Foodie">
              <categorias-screen comercio="EATS" :loading="loading" :categoria="categoriaSelected"
                :sub-categoria="subCategoriaSelected" :categorias-data="categoriasEats"
                @onclick-category="setCategorySelected" @onclick-sub-category="setSubCategorySelected"
                :sub-categorias="categoriasHijas" />
            </b-tab>
            <b-tab title="Plus">
              <categorias-screen comercio="OTROS_COMERCIOS" :loading="loading" :categoria="categoriaSelected"
                :sub-categoria="subCategoriaSelected" :categorias-data="categoriasOC"
                @onclick-category="setCategorySelected" @onclick-sub-category="setSubCategorySelected"
                :sub-categorias="categoriasHijas" />
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12" md="6">
      <editar-categoria v-if="categoriaSelected !== null && categoriaSelected.id > 0" :tipo="tipoSelected"
        :callback="getCategorias" @onchange="onImageChange" :sub-categorias="categoriasHijas"
        :categoria-selected="categoriaSelected" />
      <editar-sub-categoria v-if="subCategoriaSelected !== null && subCategoriaSelected.id > 0" :tipo="tipoSelected"
        :callback="getCategorias" @onchange="onImageChange" :subCategoriaSelected="subCategoriaSelected" />
      <crear-categoria v-else-if="categoriaSelected !== null && categoriaSelected.id == 0" :tipo="tipoSelected"
        :callback="getCategorias" @onchange="onImageChange" :categoria-selected="categoriaSelected" />
      <div v-else class="iconEmpty">
        <feather-icon icon="GridIcon" size="100" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BCard,
  BButton,
  BSpinner,
  BCardBody,
  BCardTitle,
  BCardHeader,
} from "bootstrap-vue";
import CategoriasScreen from "./componentes/CategoriasScreen.vue";
import EditarCategoria from "./componentes/EditarCategoria.vue";
import EditarSubCategoria from "./componentes/EditarSubCategoria.vue";
import CrearCategoria from "./CrearCategoria.vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BButton,
    BSpinner,
    BCardBody,
    BCardTitle,
    BCardHeader,
    CategoriasScreen,
    CrearCategoria,
    EditarCategoria,
    EditarSubCategoria,
    ToastificationContent,
  },
  data() {
    return {
      loading: true,
      tipoSelected: "",
      categoriasOC: [],
      categoriasEats: [],
      categoriasHijas: [],
      categoriasMarket: [],
      categoriaSelected: null,
      subCategoriaSelected: null,
      defaultCategory: { id: "0" },
    };
  },
  methods: {
    createCategory() {
      this.categoriaSelected = {
        id: 0,
        activo: true,
        nombre: "",
        showMain: false,
        showDestacadas: false,
        showHome: false,
        showCategorias: false,
        hijas: [],
      };
    },
    onImageChange(url, t, id) {
      if (t === 1) this.$set(this.categoriaSelected, "logo", url);
      if (t === 2) this.$set(this.categoriaSelected, "banner", url);
      if (t === 3) this.$set(this.categoriaSelected, "imagen", url);
      switch (this.categoriaSelected.lineaDeNegocio) {
        case "EATS":
          const indexE = this.categoriasEats.findIndex((c) => c.id === id);
          if (t === 1) this.$set(this.categoriasEats[indexE], "logo", url);
          if (t === 2) this.$set(this.categoriasEats[indexE], "banner", url);
          if (t === 3) this.$set(this.categoriasEats[indexE], "imagen", url);
          break;
        case "MARKET":
          const indexM = this.categoriasMarket.findIndex((c) => c.id === id);
          if (t === 1) this.$set(this.categoriasMarket[indexM], "logo", url);
          if (t === 2) this.$set(this.categoriasMarket[indexM], "banner", url);
          if (t === 3) this.$set(this.categoriasMarket[indexM], "imagen", url);
          break;
        case "OTROS_COMERCIOS":
          const indexC = this.categoriasOC.findIndex((c) => c.id === id);
          if (t === 1) this.$set(this.categoriasOC[indexC], "logo", url);
          if (t === 2) this.$set(this.categoriasOC[indexC], "banner", url);
          if (t === 3) this.$set(this.categoriasOC[indexC], "imagen", url);
          break;
        default:
          break;
      }
    },
    filterCategories(categorias) {
      const categoriasPadre = categorias.filter((c) => c.padre_id === "0");
      categoriasPadre.forEach(
        (c) =>
        (c.hijas = categorias
          .filter((ch) => ch.padre_id === c.id)
          .sort((a, b) => a.sort_order - b.sort_order))
      );
      return categoriasPadre;
    },
    setCategorySelected(cat) {
      this.categoriaSelected = this.defaultCategory;
      this.subCategoriaSelected = this.defaultCategory;
      if (cat)
        this.$nextTick(() => {
          cat.activo = cat.estado === "A";
          cat.showHome = cat.tipo.includes("H");
          cat.showMain = cat.tipo.includes("M");
          cat.showDestacadas = cat.tipo.includes("D");
          cat.showCategorias = cat.tipo.includes("C");
          this.categoriaSelected = cat;
        });
    },
    setSubCategorySelected(subcat) {
      this.categoriaSelected = this.defaultCategory;
      this.subCategoriaSelected = this.defaultCategory;
      if (subcat)
        this.$nextTick(() => {
          this.subCategoriaSelected = subcat;
        });
    },
    onChangeTab(index) {
      switch (index) {
        case 0:
          if (this.categoriasMarket.length === 0)
            this.getCategorias("MARKET", 0, true);
          this.tipoSelected = "MARKET";
          break;
        case 1:
          if (this.categoriasEats.length === 0)
            this.getCategorias("EATS", 1, true);
          this.tipoSelected = "EATS";
          break;
        case 2:
          if (this.categoriasOC.length === 0)
            this.getCategorias("OTROS_COMERCIOS", 1, true);
          this.tipoSelected = "OTROS_COMERCIOS";
          break;
        default:
          break;
      }
      this.categoriaSelected = null;
    },
    getCategorias(comercio, store, showLoading) {
      if (showLoading) this.loading = true;
      return new Promise((resolve) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Productos/Categorias",
            body: JSON.stringify({
              opt: "C",
              filas: 5000,
              pagina: 1,
              Tienda: store,
              empresa: 2,
              tokenSesion: getAuthToken(),
              TipoComercio: comercio,
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              const categorias = this.filterCategories(
                res.data.lstcategorias
              ).sort((a, b) => a.sort_order - b.sort_order);
              const childAux = res.data.lstcategorias.filter((c) => c.padre_id !== "0");
              this.categoriasHijas = childAux;
              switch (comercio) {
                case "EATS":
                  this.categoriasEats = categorias;
                  break;
                case "MARKET":
                  this.categoriasMarket = categorias;
                  break;
                case "OTROS_COMERCIOS":
                  this.categoriasOC = categorias;
                  break;
                default:
                  break;
              }
              if (this.categoriaSelected !== null)
                this.setCategorySelected(
                  categorias.find((c) => c.id === this.categoriaSelected.id)
                );
              this.loading = false;
              resolve("");
            } else {
              this.error = res.data.mensaje;
              resolve(res.data.mensaje);
            }
          })
          .catch((e) => {
            console.error(e);
            this.loading = false;
            resolve(e.message);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
