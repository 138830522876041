<template>
  <div>
    <b-form-radio-group id="vi-tipo" size="sm" class="mb-2" buttons @change="filterData" v-model="estadoElements"
      :options="['Activas', 'Inactivas', 'Todas']" button-variant="outline-primary" />
    <div v-if="isLoading" class="ldr">
      <b-spinner label="Loading..." />
    </div>
    <div v-else-if="!isLoading && categorias.length > 0">
      <draggable tag="ul" class="list-group list-group-flush cursor-move" @change="onChangeOrder" v-model="categorias">
        <transition-group type="transition" name="flip-list">
          <b-list-group-item v-for="categoria in categorias" :key="categoria.id" tag="li" :style="categoriaSelected.id === categoria.id
            ? 'background-color: rgba(4, 168, 44, 0.2)'
            : ''
            ">
            <b-media v-b-toggle="'collapse-' + categoria.id" vertical-align="top" @click="onclickCategory(categoria)">
              <template #aside>
                <b-img-lazy :src="urlResized(categoria.imagen)" blank-color="#ccc" width="50" rounded />
              </template>
              <b-badge class="mr-25" pill variant="primary" @click="onCopy(categoria.id)" ref="fatherCategory">
                {{ categoria.id }}
              </b-badge>
              <b-badge v-if="categoria.estado === 'A'" variant="light-success">
                Activa
              </b-badge>
              <b-badge v-else variant="light-secondary"> Inactiva </b-badge>
              <h5 class="media-heading mb-0 mt-50">
                {{ categoria.nombre }}
              </h5>
            </b-media>
            <b-collapse :id="'collapse-' + categoria.id">
              <b-card v-if="categoria.hijas.length > 0" title="Subcategorias" class="mt-1">
                <b-card-text class="cursor-pointer p-1 mb-0 light-success" v-for="(hija, index) in categoria.hijas"
                  :key="index" @click="onclickSubCategory(hija)" :id="subCategoriaSelected.id" :subid="hija.id" :style="Number(subCategoriaSelected.id) === Number(hija.id)
                    ? 'background-color: rgba(4, 168, 44, 0.2)'
                    : ''
                    ">
                  <b-media vertical-align="top">
                    <template #aside>
                      <b-img-lazy :src="urlResized(hija.icono)" blank-color="#ccc" width="50" rounded />
                    </template>
                    <h5 class="media-heading">
                      {{ hija.nombre }}
                    </h5>
                    <b-badge pill variant="primary" @click="onCopy(hija.id)">{{ hija.id }}</b-badge>
                    <b-badge v-if="hija.estado === 'A'" variant="light-success">
                      Activa
                    </b-badge>
                    <b-badge v-else variant="light-secondary">
                      Inactiva
                    </b-badge>
                  </b-media>
                </b-card-text>
              </b-card>
              <b-card v-else title="No hay subcategorias" class="mt-2">
              </b-card>
            </b-collapse>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </div>
    <h3 class="my-3 text-center" v-else>No hay categorias</h3>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BMedia,
  BBadge,
  BButton,
  BImgLazy,
  BSpinner,
  BFormFile,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BCollapse,
  BCard,
  VBToggle,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BImgLazy,
    BSpinner,
    draggable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BInputGroup,
    BCardHeader,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    ToastificationContent,
    BCollapse,
    BCard,
    BCardText,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    comercio: {
      type: String,
      required: true,
    },
    categoria: {
      type: Object,
      default: { id: "0" },
    },
    subCategoria: {
      type: Object,
      default: { id: "0" },
    },
    categoriasData: {
      type: Array,
      required: true,
    },
    subCategorias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      categorias: [],
      estadoElements: "Activas",
      categoriaSelected: null,
      subCategoriaSelected: null,
    };
  },
  beforeMount() {
    this.isLoading = this.loading;
    this.categoriaSelected = this.categoria || { id: "0" };
    this.subCategoriaSelected = this.subcategoria || { id: "0" };
  },
  watch: {
    loading: function (newVal) {
      this.isLoading = newVal;
    },
    categoria: function (newVal) {
      if (newVal !== null) this.categoriaSelected = newVal;
    },
    subCategoria: function (newVal) {
      this.subCategoriaSelected = newVal;
    },
    categoriasData: function (newVal) {
      this.categorias = newVal;
      this.filterData();
    },
    subCategorias: function (newVal) {
      this.subCategorias = newVal;
    },
  },
  methods: {
    async onCopy(value) {
      try {
        await navigator.clipboard.writeText(value);
      } catch (error) {
        console.error(error);
      }
    },
    filterData() {
      this.isLoading = true;
      setTimeout(() => {
        if (this.estadoElements === "Todas") {
          this.categorias = this.categoriasData;
        } else {
          this.categorias = this.categoriasData.filter(
            (c) => (this.estadoElements === "Activas") === (c.estado === "A")
          );
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 50);
      }, 200);
    },
    onChangeOrder(event) {
      const catIDs = this.categorias.map((c) => parseInt(c.id));
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategoriesOrder",
          body: JSON.stringify({
            sort: catIDs,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se actualizo correctamente el orden de categorías.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    onclickCategory(cat) {
      const aux = JSON.parse(JSON.stringify(cat));
      this.$emit("onclick-category", aux);
    },
    onclickSubCategory(cat) {
      const aux = JSON.parse(JSON.stringify(cat));
      this.$emit("onclick-sub-category", aux);
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#vi-tipo label {
  display: flex;
  align-items: center;
}

.ldr {
  padding: 3em;
  display: flex;
  justify-content: center;
}

.list-group-item {
  transition: all 1s;
}
</style>
