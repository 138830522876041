var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-img', {
    attrs: {
      "thumbnail": "",
      "fluid": "",
      "src": _vm.urlResized(_vm.imagenSRC)
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": "wildcard"
    }
  }, [_vm._v("Seleccione " + _vm._s(_vm.title))]), _c('b-form-file', {
    attrs: {
      "accept": ".jpg, .png"
    },
    on: {
      "change": _vm.encodeImageFile
    }
  })], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading || _vm.imagenSRC == _vm.imagenActual
    },
    on: {
      "click": _vm.saveImage
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Subir " + _vm._s(_vm.title))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }