<template>
  <b-row class="p-2">
    <b-col cols="12" class="text-center">
      <b-img thumbnail fluid :src="urlResized(imagenSRC)" />
    </b-col>
    <b-col cols="12">
      <label for="wildcard">Seleccione {{ title }}</label>
      <b-form-file accept=".jpg, .png" @change="encodeImageFile" />
    </b-col>
    <b-col cols="12" class="mt-2">
      <b-button block variant="primary" :disabled="loading || imagenSRC == imagenActual" @click="saveImage">
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Subir {{ title }}</div>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BFormFile, BImg, BRow, BCol, BButton, BSpinner } from "bootstrap-vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormFile,
    ToastificationContent,
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
    categoriaId: {
      type: String,
      required: true,
    },
    imagenActual: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imagenSRC: "",
      loading: false,
    };
  },
  beforeMount() {
    this.imagenSRC = this.imagenActual;
  },
  watch: {
    imagenActual: function (newVal) {
      if (newVal !== null) this.imagenSRC = newVal;
    },
  },
  methods: {
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/300x300" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    encodeImageFile(element) {
      const self = this;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          self.imagenSRC = srcData;
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    saveImage(e) {
      e && e.preventDefault();
      this.loading = true;
      const self = this;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: this.imagenSRC,
            folder: 'category',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            const imageURL = res.data[0].url;
            self.$http
              .post(self.$store.state.app.middlewareURL, {
                path: "/Administracion/ImgCategorias",
                body: JSON.stringify({
                  id: self.categoriaId,
                  tipo: self.type,
                  empresa: 2,
                  URLFinal: imageURL,
                  tokenSesion: getAuthToken(),
                }),
              })
              .then((res) => {
                self.loading = false;
                if (res.data.bOk) {
                  self.hide(true);
                  self.$emit("onchange", res.data.url, this.type, this.categoriaId);
                  setTimeout(() => {
                    self.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Éxito",
                        icon: "CheckIcon",
                        text: `Se cambio ${this.title} correctamente.`,
                        variant: "success",
                      },
                    });
                  }, 500);
                } else {
                  throw new Error(res.data.mensaje);
                }
              });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>