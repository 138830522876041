var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.toggleShowModal
    }
  }, [_vm._v(" Cambiar Imagenes ")]), _c('b-modal', {
    attrs: {
      "id": "modal-center",
      "title": "Cambiar Imagenes",
      "centered": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModalImagenes,
      callback: function callback($$v) {
        _vm.showModalImagenes = $$v;
      },
      expression: "showModalImagenes"
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "vertical": "",
      "nav-class": "nav-left",
      "content-class": "col-12 col-md-9 mt-1 mt-md-0",
      "nav-wrapper-class": "col-md-3 col-12"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ImageIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Logo")])];
      },
      proxy: true
    }])
  }, [_c('image-change', {
    attrs: {
      "type": 1,
      "hide": _vm.toggleShowModal,
      "title": "el logo [800 x 800]",
      "categoria-id": _vm.categoria.id,
      "imagen-actual": _vm.categoria.icono
    },
    on: {
      "onchange": _vm.onImageChange
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ImageIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Banner")])];
      },
      proxy: true
    }])
  }, [_c('image-change', {
    attrs: {
      "type": 2,
      "hide": _vm.toggleShowModal,
      "title": "el banner [1850 x 500]",
      "categoria-id": _vm.categoria.id,
      "imagen-actual": _vm.categoria.banner
    },
    on: {
      "onchange": _vm.onImageChange
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ImageIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Imagen")])];
      },
      proxy: true
    }])
  }, [_c('image-change', {
    attrs: {
      "type": 3,
      "hide": _vm.toggleShowModal,
      "title": "la imagen [1200 x 675]",
      "categoria-id": _vm.categoria.id,
      "imagen-actual": _vm.categoria.imagen
    },
    on: {
      "onchange": _vm.onImageChange
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }