<template>
  <div>
    <b-button variant="primary" @click="toggleShowModal" block>
      Cambiar Imagenes
    </b-button>

    <b-modal
      id="modal-center"
      title="Cambiar Imagenes"
      v-model="showModalImagenes"
      centered
      hide-footer
    >
      <b-tabs
        pills
        vertical
        nav-class="nav-left"
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-wrapper-class="col-md-3 col-12"
      >
        <b-tab active>
          <template #title>
            <feather-icon icon="ImageIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Logo</span>
          </template>

          <image-change
            :type="1"
            :hide="toggleShowModal"
            title="el logo [800 x 800]"
            @onchange="onImageChange"
            :categoria-id="categoria.id"
            :imagen-actual="categoria.icono"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="ImageIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Banner</span>
          </template>

          <image-change
            :type="2"
            :hide="toggleShowModal"
            title="el banner [1850 x 500]"
            @onchange="onImageChange"
            :categoria-id="categoria.id"
            :imagen-actual="categoria.banner"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="ImageIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Imagen</span>
          </template>

          <image-change
            :type="3"
            :hide="toggleShowModal"
            title="la imagen [1200 x 675]"
            @onchange="onImageChange"
            :categoria-id="categoria.id"
            :imagen-actual="categoria.imagen"
          />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>
<script>
import { BTab, BTabs, BModal, BButton } from "bootstrap-vue";
import ImageChange from "./ImageChange.vue";

export default {
  components: {
    BTab,
    BTabs,
    BModal,
    BButton,
    ImageChange,
  },
  props: {
    categoriaSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoria: null,
      showModalImagenes: false,
    };
  },
  beforeMount() {
    this.categoria = this.categoriaSelected;
  },
  watch: {
    categoriaSelected: function (newVal) {
      if (newVal !== null) this.categoria = newVal;
    },
  },
  methods: {
    onImageChange(url, tipo, id) {
      this.$emit("onchange", url, tipo, id);
    },
    toggleShowModal() {
      this.showModalImagenes = !this.showModalImagenes;
    },
  },
};
</script>