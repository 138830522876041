var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.showLoader,
      "rounded": "sm"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Actualizar Subcategoria")]), _c('b-form-group', {
    attrs: {
      "label": "Activa",
      "label-for": "vi-activo-ch"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo-ch",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.activa,
      callback: function callback($$v) {
        _vm.activa = $$v;
      },
      expression: "activa"
    }
  })], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.subCategoria.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.subCategoria, "nombre", $$v);
      },
      expression: "subCategoria.nombre"
    }
  })], 1)], 1)], 1), _vm.subCategoria !== null && _vm.subCategoria.id !== '0' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('modal-imagenes-categorias', {
    attrs: {
      "categoria-selected": _vm.subCategoria
    },
    on: {
      "onchange": _vm.onImageChange
    }
  })], 1) : _vm._e(), _vm.subCategoria !== null && _vm.subCategoria.id !== '0' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('sidebar-descuentos-categorias', {
    attrs: {
      "categoria-selected": _vm.subCategoria
    }
  })], 1) : _vm._e(), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }