<template>
  <b-overlay :show="showLoader" rounded="sm">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Crear Categoría {{ tipoComercio }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-for="vi-nombre">
              <b-input-group class="input-group-merge">
                <b-form-input id="vi-nombre" placeholder="Nombre" v-model="categoriaSelected.nombre" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group v-if="tipo === 'MARKET'">
              <b-form-checkbox switch inline v-model="categoriaSelected.showMain">
                Mostrar en el Home Principal
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox switch inline v-model="categoriaSelected.showDestacadas">
                Mostrar en categorías destacadas
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox switch inline v-model="categoriaSelected.showHome">
                Mostrar en el Home de {{ tipoComercio }}
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox switch inline v-model="categoriaSelected.showCategorias">
                Mostrar en la pantalla Categorías
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button type="submit" variant="primary" class="mr-1 mt-2" @click="savedata" :disabled="loading">
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BMedia,
  BBadge,
  BButton,
  BSpinner,
  BOverlay,
  BCardBody,
  BFormFile,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Acciones from "./componentes/Acciones.vue";
import Draggable from "vuedraggable";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getAuthToken } from "@/auth/utils";
// import { mainHomeRequests } from "../cache/requests";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    BSpinner,
    BOverlay,
    Acciones,
    Draggable,
    BCardBody,
    BFormFile,
    BCardTitle,
    BFormInput,
    BFormGroup,
    AppCollapse,
    BInputGroup,
    BCardHeader,
    BButtonGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    AppCollapseItem,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    tipo: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    categoriaSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showLoader: false,
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
    };
  },
  watch: {
    categoriaSelected: function (newVal) {
      if (newVal !== null) this.categoria = newVal;
    },
  },
  methods: {
    setShowLoader(show) {
      this.showLoader = show;
    },
    savedata() {
      this.showLoader = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategory",
          body: JSON.stringify({
            empresa: 2,
            categoria: {
              id: this.categoriaSelected.id,
              tipo: this.generateType,
              nombre: this.categoriaSelected.nombre,
              activa: this.categoriaSelected.activo,
              idPadre: 0,
              lineaDeNegocio: this.tipo,
            },
            store: this.tipo !== 'MARKET',
            tokenSesion: getAuthToken(),
          }),
        }).then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            // this.rebuildCache();
            this.showLoader = false;
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se creó correctamente la categoría.",
                  title: "Éxito",
                  variant: "success",
                },
              });
              return;
            }
          }
          this.showLoader = false;
          throw new Error(res.data.mensaje);
        }).catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader = false;
        });
    },
  },
  computed: {
    generateType() {
      let tipo = "";
      if (this.categoriaSelected.showHome) tipo += "H";
      if (this.categoriaSelected.showMain) tipo += "M";
      if (this.categoriaSelected.showCategorias) tipo += "C";
      if (this.categoriaSelected.showDestacadas) tipo += "D";
      if (tipo.length === 0) tipo += "N";
      return tipo;
    },
    tipoComercio() {
      switch (this.tipo) {
        case "EATS":
          return "Foodie";
        case "MARKET":
          return "Market";
        case "OTROS_COMERCIOS":
          return "Plus";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#vi-tipo label {
  display: flex;
  align-items: center;
}

.dpf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mdm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>