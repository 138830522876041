<template>
  <div>
    <b-button @click="showSideBar = true" variant="primary" block>
      Descuentos
    </b-button>
    <b-sidebar id="sidebar-right" right width="55em" shadow v-model="showSideBar" backdrop bg-variant="white">
      <b-row class="p-2 m-0">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="5" v-if="activeDiscount !== null">
              <b-card title="Descuento Activo" class="card-developer-meetup" border-variant="secondary">
                <!-- metting header -->
                <div class="meetup-header d-flex align-items-center">
                  <div class="meetup-day">
                    <h1 class="mb-0">
                      {{
                        discountValue(
                          activeDiscount.tipo,
                          activeDiscount.valor,
                          activeDiscount.porcentaje
                        )
                      }}
                    </h1>
                  </div>
                  <div class="my-auto">
                    <b-card-title class="mb-25"> Tipo </b-card-title>
                    <b-card-text class="mb-0">
                      {{ discountType(activeDiscount.tipo) }}
                    </b-card-text>
                  </div>
                </div>
                <!--/ metting header -->

                <!-- media -->
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="34">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="mb-0">Fecha Inicio</h6>
                    <small>
                      {{ formattedDate(activeDiscount.fechaInicio) }}
                      {{ formattedHour(activeDiscount.fechaInicio) }}
                    </small>
                  </b-media-body>
                </b-media>

                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="34">
                      <feather-icon icon="CalendarIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="mb-0">Fecha Fin</h6>
                    <small>
                      {{ formattedDate(activeDiscount.fechaFin) }}
                      {{ formattedHour(activeDiscount.fechaFin) }}
                    </small>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
            <b-col cols="12" md="7">
              <b-card title="Nuevo descuento" border-variant="secondary">
                <b-form-radio-group size="sm" v-model="typeSelected" buttons :options="optionsType"
                  button-variant="outline-primary" />
                <b-form-group class="mt-1" :label="typeSelected === '01' ? 'Porcentaje' : 'Valor'">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon :icon="typeSelected === '01'
                        ? 'PercentIcon'
                        : 'DollarSignIcon'
                        " />
                    </b-input-group-prepend>
                    <b-form-input size="sm" v-model="cantidad" type="number" />
                  </b-input-group>
                </b-form-group>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Fecha Inicio">
                      <flat-pickr class="form-control form-control-sm" v-model="fechaINI" :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        altInput: true,
                        altFormat: 'd/m/Y H:i',
                      }" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Fecha Fin">
                      <flat-pickr class="form-control form-control-sm" v-model="fechaFIN" :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        altInput: true,
                        altFormat: 'd/m/Y H:i',
                      }" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button block variant="primary" @click="createDiscount" :disabled="saving">
                  <b-spinner v-if="saving" small label="Loading..." />
                  <div v-else>Guardar</div>
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <!-- table -->
          <vue-good-table :rows="discounts" :columns="columns" :sort-options="{
            enabled: false,
          }" :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }" :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }" :isLoading.sync="loading">
            <template slot="table-row" slot-scope="props">
              <!-- Column: Tipo -->
              <span v-if="props.column.field === 'tipo'">
                <b-badge :variant="discountColor(props.row.tipo)">
                  {{ discountType(props.row.tipo) }}
                </b-badge>
              </span>

              <!-- Column: Valor -->
              <span v-else-if="props.column.field === 'Dvalor'">
                {{
                  discountValue(
                    props.row.tipo,
                    props.row.valor,
                    props.row.porcentaje
                  )
                }}
              </span>

              <!-- Column: FechaIni -->
              <span v-else-if="props.column.field === 'fechaInicio'">
                <p class="mb-0">{{ formattedDate(props.row.fechaInicio) }}</p>
                <p class="mb-0">{{ formattedHour(props.row.fechaInicio) }}</p>
              </span>

              <!-- Column: FechaFin -->
              <span v-else-if="props.column.field === 'fechaFin'">
                <p class="mb-0">{{ formattedDate(props.row.fechaFin) }}</p>
                <p class="mb-0">{{ formattedHour(props.row.fechaFin) }}</p>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'estado'">
                <b-badge :variant="statusVariant(
                  props.row.estado,
                  props.row.fechaInicio,
                  props.row.fechaFin
                )
                  ">
                  {{
                    discountStatus(
                      props.row.estado,
                      props.row.fechaInicio,
                      props.row.fechaFin
                    )
                  }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <!--  <b-dropdown-item>
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Editar</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="onclickDelete($event, props.row.id_desc)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div>
                  <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                    align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                      ">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMedia,
  BBadge,
  BAvatar,
  BButton,
  BSidebar,
  BSpinner,
  BCardText,
  BDropdown,
  BCardBody,
  BMediaBody,
  BCardTitle,
  BFormGroup,
  BCardGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BMediaAside,
  BInputGroup,
  BFormCheckbox,
  BDropdownItem,
  BFormRadioGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";

import moment from "moment-timezone";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BAvatar,
    vSelect,
    BButton,
    BSpinner,
    BSidebar,
    BCardText,
    BCardBody,
    BDropdown,
    flatPickr,
    BCardTitle,
    BMediaBody,
    BFormGroup,
    BCardGroup,
    BFormInput,
    BFormSelect,
    BMediaAside,
    BInputGroup,
    BPagination,
    VueGoodTable,
    BFormCheckbox,
    BDropdownItem,
    BFormRadioGroup,
    BInputGroupPrepend,
    ToastificationContent,
  },
  props: {
    categoriaSelected: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      loading: true,
      columns: [
        {
          label: "Tipo",
          field: "tipo",
        },
        {
          label: "Valor",
          field: "Dvalor",
        },
        {
          label: "Inicio",
          field: "fechaInicio",
        },
        {
          label: "Fin",
          field: "fechaFin",
        },
        {
          label: "Estado",
          field: "estado",
        },
        {
          label: "Acciones",
          field: "action",
        },
      ],

      fechaINI: "",
      fechaFIN: "",
      cantidad: "",
      discounts: [],
      searchTerm: "",
      pageLength: 5,
      optionsType: [
        { text: "Porcentaje", value: "01" },
        { text: "Valor", value: "02" },
      ],
      showSideBar: false,
      typeSelected: "01",
      activeDiscount: null,
    };
  },
  mounted() {
    this.getDiscounts();
  },
  methods: {
    onclickDelete(event, discountID) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm(
          `Desea eliminar el descuento de la categoria ${this.categoriaSelected.nombre}?.`,
          {
            title: "Eliminar Descuento",
            size: "sm",
            okTitle: "Eliminar",
            centered: true,
            okVariant: "danger",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
          }
        )
        .then((value) => {
          if (value) this.deleteDiscount(discountID);
        });
    },
    discountIsActive(status, sDateINI, sDateEND) {
      if (status === "E") return false;
      const now = moment();
      const dateINI = moment(sDateINI);
      const dateEND = moment(sDateEND);
      if (now > dateINI && now < dateEND) return true;
    },
    discountStatus(status, sDateINI, sDateEND) {
      if (status === "E") return "Eliminado";
      const now = moment();
      const dateINI = moment(sDateINI);
      const dateEND = moment(sDateEND);
      if (now < dateINI) return "No iniciado";
      if (now > dateINI && now < dateEND) return "Activo";
      if (now > dateEND) return "Finalizado";
      return "No definido";
    },
    statusVariant(status, sDateINI, sDateEND) {
      if (status === "E") return "light-danger";
      const now = moment();
      const dateINI = moment(sDateINI);
      const dateEND = moment(sDateEND);
      if (now < dateINI) return "light-info";
      if (now > dateINI && now < dateEND) return "light-primary";
      if (now > dateEND) return "light-secondary";
      return "danger";
    },
    formattedDate(originalDate) {
      return moment(originalDate).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(originalDate).format("hh:mm a");
    },
    getDiscounts() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Categoria/ConsCategoriaDesc",
          body: JSON.stringify({
            _event: "C",
            empresa: 2,
            fechaFin: "2020-06-16", //Quemado porque el api falla si no se envia una fecha
            fechaInicio: "2020-06-16", //Quemado porque el api falla si no se envia una fecha
            tokenSesion: getAuthToken(),
            categoria_id: this.categoriaSelected.id,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.discounts = res.data.descuentos;
            this.discounts.some((d) => {
              if (this.discountIsActive(d.tipo, d.fechaInicio, d.fechaFin)) {
                this.activeDiscount = d;
                return true;
              }
              return false;
            });
          } else {
            this.error = res.data.mensaje;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.error = e.message;
          this.loading = false;
        });
    },
    deleteDiscount(discountID) {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Categoria/ActCategoriaDesc",
          body: JSON.stringify({
            id_desc: discountID,
            _event: "E",
            empresa: 2,
            fechaFin: "2020-06-16", //Quemado porque el api falla si no se envia una fecha
            fechaInicio: "2020-06-16", //Quemado porque el api falla si no se envia una fecha
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getDiscounts();
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se elimino correctamente el descuento de categoria.",
                  title: "Exito",
                  variant: "success",
                },
              });
            }, 1000);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: res.data.mensaje,
                title: "Error",
                variant: "danger",
              },
            });
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    createDiscount(e) {
      var fechaFIN = moment(this.fechaFIN).tz("America/Guayaquil").format()
      var fechaINI = moment(this.fechaINI).tz("America/Guayaquil").format()
      e && e.preventDefault();
      this.saving = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Categoria/ActCategoriaDesc",
          body: JSON.stringify({
            tipo: this.typeSelected,
            valor: this.typeSelected === "01" ? 0 : this.cantidad,
            _event: "I",
            empresa: 2,
            fechaFin: fechaFIN,
            porcentaje: this.typeSelected === "01" ? this.cantidad : 0,
            fechaInicio: fechaINI,
            tokenSesion: getAuthToken(),
            categoria_id: this.categoriaSelected.id,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.clearForm();
            this.getDiscounts();
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se creo correctamente el descuento de categoria.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            throw new Error(res.data.mensaje);
          }
          this.saving = false;
        })
        .catch((e) => {
          this.saving = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    clearForm() {
      this.cantidad = "";
      this.fechaINI = "";
      this.fechaFIN = "";
    },
  },
  computed: {
    discountValue() {
      return (type, value, percent) =>
        type === "01" ? `${percent}%` : `$${value}`;
    },
    discountType() {
      const discountType = {
        "01": "Porcentaje",
        "02": "Monto",
      };
      return (type) => discountType[type];
    },
    discountColor() {
      const discountColor = {
        "01": "light-primary",
        "02": "light-warning",
      };
      return (type) => discountColor[type];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

table.vgt-table {
  font-size: 12px !important;
}
</style>