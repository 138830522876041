<template>
  <b-overlay :show="showLoader" rounded="sm">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Actualizar Subcategoria</b-card-title>
        <b-form-group label="Activa" label-for="vi-activo-ch">
          <b-form-checkbox
            id="vi-activo-ch"
            name="check-button"
            class="custom-control-primary"
            switch
            v-model="activa"
          />
        </b-form-group>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" class="mb-2">
            <b-form-group label="Nombre" label-for="vi-nombre">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="vi-nombre"
                  placeholder="Nombre"
                  v-model="subCategoria.nombre"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            v-if="subCategoria !== null && subCategoria.id !== '0'"
          >
            <modal-imagenes-categorias
              @onchange="onImageChange"
              :categoria-selected="subCategoria"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            v-if="subCategoria !== null && subCategoria.id !== '0'"
          >
            <sidebar-descuentos-categorias :categoria-selected="subCategoria" />
          </b-col>

          <b-col cols="12" class="mt-2">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 mt-2"
              @click="savedata"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BMedia,
  BBadge,
  BButton,
  BSpinner,
  BOverlay,
  BCardBody,
  BFormFile,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Acciones from "./Acciones.vue";
import Draggable from "vuedraggable";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getAuthToken } from "@/auth/utils";
import { mainHomeRequests } from "../../cache/requests";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AsignarCrearCategorias from "./AsignarCrearCategorias.vue";
import ModalImagenesCategorias from "./ModalImagenesCategorias.vue";
import SidebarDescuentosCategorias from "./SidebarDescuentosCategorias.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BSpinner,
    BOverlay,
    Acciones,
    Draggable,
    BCardBody,
    BFormFile,
    BCardTitle,
    BFormInput,
    BFormGroup,
    AppCollapse,
    BInputGroup,
    BCardHeader,
    BButtonGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    AppCollapseItem,
    ToastificationContent,
    AsignarCrearCategorias,
    ModalImagenesCategorias,
    SidebarDescuentosCategorias,
  },
  directives: {
    Ripple,
  },
  props: {
    tipo: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    subCategoriaSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      bodegas: [],
      loading: false,
      sectores: [],
      imageSRC: null,
      subCategoria: this.subCategoriaSelected,
      showLoader: false,
      loadingImage: false,
      activa: this.subCategoriaSelected.estado === "A",
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
    };
  },
  methods: {
    onImageChange(url, tipo, id) {
      this.$emit("onchange", url, tipo, id);
    },
    editSubCategory(cat) {
      this.$refs["crearEditar"].editar(cat);
    },
    setShowLoader(show) {
      this.showLoader = show;
    },
    deleteConfirm(cat) {
      this.$bvModal
        .msgBoxConfirm(`Desea eliminar la subcategoria [${cat.nombre}].`, {
          title: "Eliminar subcategoria",
          size: "sm",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deleteAssign(cat.id);
        });
    },
    deleteAssign(childID) {
      this.showLoader = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Categoria/CategoriaPadresAdmin",
          body: JSON.stringify({
            opt: "D",
            IDEmpresa: 2,
            tokenSesion: getAuthToken(),
            IDCategoriaHija: childID,
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            this.showLoader = false;
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se actualizo correctamente la categoria.",
                  title: "Exito",
                  variant: "success",
                },
              });
              return;
            }
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: res.data.mensaje,
              title: "Error",
              variant: "danger",
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader = false;
        });
    },
    savedata() {
      const categoria = {
        id: Number(this.subCategoria.id),
        tipo: this.generateType,
        nombre: this.subCategoria.nombre,
        activa: this.activa,
        idPadre: Number(this.subCategoria.padre_id),
      };
      this.showLoader = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategory",
          body: JSON.stringify({
            empresa: 2,
            categoria,
            tokenSesion: getAuthToken(),
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            // this.rebuildCache();
            this.showLoader = false;
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se actualizo correctamente la subcategoria.",
                  title: "Exito",
                  variant: "success",
                },
              });
              return;
            }
          }
          this.showLoader = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: res.data.mensaje,
              title: "Error",
              variant: "danger",
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader = false;
        });
    },
    onChangeOrder(event) {
      const catIDs = this.subCategoria.hijas.map((c) => parseInt(c.id));
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategoriesOrder",
          body: JSON.stringify({
            sort: catIDs,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text:
                  "Se actualizo correctamente el orden de las subcategorias.",
                title: "Exito",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: res.data.mensaje,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    async rebuildCache() {
      const keys = [];
      await new Promise((resolve, reject) =>
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              this.sectores = res.data.lssectores;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          })
      );
      if (this.tipo === "MARKET") {
        mainHomeRequests.forEach((key) => keys.push(key));
        this.bodegas.forEach((b) => {
          keys.push({
            path: "/Customer/AppHome",
            body: { tipo: this.tipo, bodega: b.id },
          });
          keys.push({
            path: "/Productos/ConsultaCategoriasRewok",
            body: {
              Tipo: "C",
              Tienda: false,
              IDBodega: b.id,
              IDEmpresa: 2,
              TipoComercio: this.tipo,
            },
          });
        });
      } else
        this.sectores.forEach((s) => {
          keys.push({
            path: "/Customer/AppHome",
            body: { tipo: this.tipo, sector: s.id },
          });
          keys.push({
            path: "/Productos/ConsultaCategoriasRewok",
            body: {
              Tipo: "C",
              Tienda: true,
              IDSector: s.id,
              IDEmpresa: 2,
              tokenSesion: getAuthToken(),
              TipoComercio: this.tipo,
            },
          });
        });
      keys.forEach((key) => {
        key.body.refreshCache = true;
        this.$http.post(this.$store.state.app.nodeMiddleware, key);
      });
    },
  },
  computed: {
    generateType() {
      let tipo = "";
      if (this.subCategoria.showHome) tipo += "H";
      if (this.subCategoria.showMain) tipo += "M";
      if (this.subCategoria.showCategorias) tipo += "C";
      if (this.subCategoria.showDestacadas) tipo += "D";
      if (tipo.length === 0) tipo += "N";
      return tipo;
    },
    tipoComercio() {
      switch (this.tipo) {
        case "EATS":
          return "Foodie";
        case "MARKET":
          return "Market";
        case "OTROS_COMERCIOS":
          return "Plus";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
.dpf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mdm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
