var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.showLoader,
      "rounded": "sm"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Actualizar Categoria")]), _c('b-form-group', {
    attrs: {
      "label": "Activa",
      "label-for": "vi-activo-ch"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo-ch",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.categoria.activo,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "activo", $$v);
      },
      expression: "categoria.activo"
    }
  })], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.categoria.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "nombre", $$v);
      },
      expression: "categoria.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.tipo === 'MARKET' ? _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoria.showMain,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "showMain", $$v);
      },
      expression: "categoria.showMain"
    }
  }, [_vm._v(" Mostrar en el Home Principal ")])], 1) : _vm._e(), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoria.showDestacadas,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "showDestacadas", $$v);
      },
      expression: "categoria.showDestacadas"
    }
  }, [_vm._v(" Mostrar en categorias destacadas ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoria.showHome,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "showHome", $$v);
      },
      expression: "categoria.showHome"
    }
  }, [_vm._v(" Mostrar en el Home de " + _vm._s(_vm.tipoComercio) + " ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoria.showCategorias,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "showCategorias", $$v);
      },
      expression: "categoria.showCategorias"
    }
  }, [_vm._v(" Mostrar en la pantalla Categorias ")])], 1)], 1), _vm.categoria !== null && _vm.categoria.id !== '0' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('modal-imagenes-categorias', {
    attrs: {
      "categoria-selected": _vm.categoria
    },
    on: {
      "onchange": _vm.onImageChange
    }
  })], 1) : _vm._e(), _vm.categoria !== null && _vm.categoria.id !== '0' ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('sidebar-descuentos-categorias', {
    attrs: {
      "categoria-selected": _vm.categoria
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "dpf my-2"
  }, [_c('h6', {
    staticClass: "m-0"
  }, [_vm._v("Subcategorias")]), _c('asignar-crear-categorias', {
    ref: "crearEditar",
    attrs: {
      "tipo": _vm.tipo,
      "callback": _vm.callback,
      "categoria": _vm.categoria,
      "show-loader": _vm.setShowLoader,
      "tipo-comercio": _vm.tipoComercio,
      "sub-categorias": _vm.categoriasHija
    }
  })], 1), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    on: {
      "change": _vm.onChangeOrder
    },
    model: {
      value: _vm.categoria.hijas,
      callback: function callback($$v) {
        _vm.$set(_vm.categoria, "hijas", $$v);
      },
      expression: "categoria.hijas"
    }
  }, [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.categoria.hijas, function (categoria) {
    return _c('b-list-group-item', {
      key: categoria.id,
      attrs: {
        "tag": "li"
      }
    }, [_c('b-media', {
      attrs: {
        "vertical-align": "top"
      }
    }, [_c('div', {
      staticClass: "lnk"
    }, [_c('div', [_c('h5', {
      staticClass: "media-heading"
    }, [_vm._v(" " + _vm._s(categoria.nombre) + " ")]), categoria.estado === 'A' ? _c('b-badge', {
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(" Activa ")]) : _c('b-badge', {
      attrs: {
        "variant": "light-secondary"
      }
    }, [_vm._v(" Inactiva ")])], 1), _c('div', [false ? _c('b-button', {
      staticClass: "btn-icon",
      attrs: {
        "variant": "flat-secondary"
      },
      on: {
        "click": function click($event) {
          return _vm.editSubCategory(categoria);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EditIcon"
      }
    })], 1) : _vm._e(), _c('b-button', {
      staticClass: "btn-icon",
      attrs: {
        "variant": "flat-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteConfirm(categoria);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Trash2Icon"
      }
    })], 1)], 1)])])], 1);
  }), 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }