var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-radio-group', {
    staticClass: "mb-2",
    attrs: {
      "id": "vi-tipo",
      "size": "sm",
      "buttons": "",
      "options": ['Activas', 'Inactivas', 'Todas'],
      "button-variant": "outline-primary"
    },
    on: {
      "change": _vm.filterData
    },
    model: {
      value: _vm.estadoElements,
      callback: function callback($$v) {
        _vm.estadoElements = $$v;
      },
      expression: "estadoElements"
    }
  }), _vm.isLoading ? _c('div', {
    staticClass: "ldr"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : !_vm.isLoading && _vm.categorias.length > 0 ? _c('div', [_c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    on: {
      "change": _vm.onChangeOrder
    },
    model: {
      value: _vm.categorias,
      callback: function callback($$v) {
        _vm.categorias = $$v;
      },
      expression: "categorias"
    }
  }, [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.categorias, function (categoria) {
    return _c('b-list-group-item', {
      key: categoria.id,
      style: _vm.categoriaSelected.id === categoria.id ? 'background-color: rgba(4, 168, 44, 0.2)' : '',
      attrs: {
        "tag": "li"
      }
    }, [_c('b-media', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'collapse-' + categoria.id,
        expression: "'collapse-' + categoria.id"
      }],
      attrs: {
        "vertical-align": "top"
      },
      on: {
        "click": function click($event) {
          return _vm.onclickCategory(categoria);
        }
      },
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-img-lazy', {
            attrs: {
              "src": _vm.urlResized(categoria.imagen),
              "blank-color": "#ccc",
              "width": "50",
              "rounded": ""
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-badge', {
      ref: "fatherCategory",
      refInFor: true,
      staticClass: "mr-25",
      attrs: {
        "pill": "",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onCopy(categoria.id);
        }
      }
    }, [_vm._v(" " + _vm._s(categoria.id) + " ")]), categoria.estado === 'A' ? _c('b-badge', {
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(" Activa ")]) : _c('b-badge', {
      attrs: {
        "variant": "light-secondary"
      }
    }, [_vm._v(" Inactiva ")]), _c('h5', {
      staticClass: "media-heading mb-0 mt-50"
    }, [_vm._v(" " + _vm._s(categoria.nombre) + " ")])], 1), _c('b-collapse', {
      attrs: {
        "id": 'collapse-' + categoria.id
      }
    }, [categoria.hijas.length > 0 ? _c('b-card', {
      staticClass: "mt-1",
      attrs: {
        "title": "Subcategorias"
      }
    }, _vm._l(categoria.hijas, function (hija, index) {
      return _c('b-card-text', {
        key: index,
        staticClass: "cursor-pointer p-1 mb-0 light-success",
        style: Number(_vm.subCategoriaSelected.id) === Number(hija.id) ? 'background-color: rgba(4, 168, 44, 0.2)' : '',
        attrs: {
          "id": _vm.subCategoriaSelected.id,
          "subid": hija.id
        },
        on: {
          "click": function click($event) {
            return _vm.onclickSubCategory(hija);
          }
        }
      }, [_c('b-media', {
        attrs: {
          "vertical-align": "top"
        },
        scopedSlots: _vm._u([{
          key: "aside",
          fn: function fn() {
            return [_c('b-img-lazy', {
              attrs: {
                "src": _vm.urlResized(hija.icono),
                "blank-color": "#ccc",
                "width": "50",
                "rounded": ""
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('h5', {
        staticClass: "media-heading"
      }, [_vm._v(" " + _vm._s(hija.nombre) + " ")]), _c('b-badge', {
        attrs: {
          "pill": "",
          "variant": "primary"
        },
        on: {
          "click": function click($event) {
            return _vm.onCopy(hija.id);
          }
        }
      }, [_vm._v(_vm._s(hija.id))]), hija.estado === 'A' ? _c('b-badge', {
        attrs: {
          "variant": "light-success"
        }
      }, [_vm._v(" Activa ")]) : _c('b-badge', {
        attrs: {
          "variant": "light-secondary"
        }
      }, [_vm._v(" Inactiva ")])], 1)], 1);
    }), 1) : _c('b-card', {
      staticClass: "mt-2",
      attrs: {
        "title": "No hay subcategorias"
      }
    })], 1)], 1);
  }), 1)], 1)], 1) : _c('h3', {
    staticClass: "my-3 text-center"
  }, [_vm._v("No hay categorias")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }