var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.showLoader,
      "rounded": "sm"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Crear Categoría " + _vm._s(_vm.tipoComercio))])], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.categoriaSelected.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.categoriaSelected, "nombre", $$v);
      },
      expression: "categoriaSelected.nombre"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.tipo === 'MARKET' ? _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoriaSelected.showMain,
      callback: function callback($$v) {
        _vm.$set(_vm.categoriaSelected, "showMain", $$v);
      },
      expression: "categoriaSelected.showMain"
    }
  }, [_vm._v(" Mostrar en el Home Principal ")])], 1) : _vm._e(), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoriaSelected.showDestacadas,
      callback: function callback($$v) {
        _vm.$set(_vm.categoriaSelected, "showDestacadas", $$v);
      },
      expression: "categoriaSelected.showDestacadas"
    }
  }, [_vm._v(" Mostrar en categorías destacadas ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoriaSelected.showHome,
      callback: function callback($$v) {
        _vm.$set(_vm.categoriaSelected, "showHome", $$v);
      },
      expression: "categoriaSelected.showHome"
    }
  }, [_vm._v(" Mostrar en el Home de " + _vm._s(_vm.tipoComercio) + " ")])], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.categoriaSelected.showCategorias,
      callback: function callback($$v) {
        _vm.$set(_vm.categoriaSelected, "showCategorias", $$v);
      },
      expression: "categoriaSelected.showCategorias"
    }
  }, [_vm._v(" Mostrar en la pantalla Categorías ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }