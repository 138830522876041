<template>
  <b-overlay :show="showLoader" rounded="sm">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Actualizar Categoria</b-card-title>
        <b-form-group label="Activa" label-for="vi-activo-ch">
          <b-form-checkbox
            id="vi-activo-ch"
            name="check-button"
            class="custom-control-primary"
            switch
            v-model="categoria.activo"
          />
        </b-form-group>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-for="vi-nombre">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="vi-nombre"
                  placeholder="Nombre"
                  v-model="categoria.nombre"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group v-if="tipo === 'MARKET'">
              <b-form-checkbox switch inline v-model="categoria.showMain">
                Mostrar en el Home Principal
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox switch inline v-model="categoria.showDestacadas">
                Mostrar en categorias destacadas
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox switch inline v-model="categoria.showHome">
                Mostrar en el Home de {{ tipoComercio }}
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox switch inline v-model="categoria.showCategorias">
                Mostrar en la pantalla Categorias
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            v-if="categoria !== null && categoria.id !== '0'"
          >
            <modal-imagenes-categorias
              @onchange="onImageChange"
              :categoria-selected="categoria"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            v-if="categoria !== null && categoria.id !== '0'"
          >
            <sidebar-descuentos-categorias :categoria-selected="categoria" />
          </b-col>

          <b-col cols="12">
            <div class="dpf my-2">
              <h6 class="m-0">Subcategorias</h6>
              <asignar-crear-categorias
                ref="crearEditar"
                :tipo="tipo"
                :callback="callback"
                :categoria="categoria"
                :show-loader="setShowLoader"
                :tipo-comercio="tipoComercio"
                :sub-categorias="categoriasHija"
              />
            </div>

            <draggable
              tag="ul"
              v-model="categoria.hijas"
              class="list-group list-group-flush cursor-move"
              @change="onChangeOrder"
            >
              <transition-group type="transition" name="flip-list">
                <b-list-group-item
                  v-for="categoria in categoria.hijas"
                  :key="categoria.id"
                  tag="li"
                >
                  <b-media vertical-align="top">
                    <div class="lnk">
                      <div>
                        <h5 class="media-heading">
                          {{ categoria.nombre }}
                        </h5>
                        <b-badge
                          v-if="categoria.estado === 'A'"
                          variant="light-success"
                        >
                          Activa
                        </b-badge>
                        <b-badge v-else variant="light-secondary">
                          Inactiva
                        </b-badge>
                      </div>
                      <div>
                        <b-button
                          v-if="false"
                          variant="flat-secondary"
                          class="btn-icon"
                          @click="editSubCategory(categoria)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                          variant="flat-danger"
                          class="btn-icon"
                          @click="deleteConfirm(categoria)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </div>
                  </b-media>
                </b-list-group-item>
              </transition-group>
            </draggable>
          </b-col>

          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 mt-2"
              @click="savedata"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BMedia,
  BBadge,
  BButton,
  BSpinner,
  BOverlay,
  BCardBody,
  BFormFile,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Acciones from "./Acciones.vue";
import Draggable from "vuedraggable";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getAuthToken } from "@/auth/utils";
import { mainHomeRequests } from "../../cache/requests";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AsignarCrearCategorias from "./AsignarCrearCategorias.vue";
import ModalImagenesCategorias from "./ModalImagenesCategorias.vue";
import SidebarDescuentosCategorias from "./SidebarDescuentosCategorias.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BSpinner,
    BOverlay,
    Acciones,
    Draggable,
    BCardBody,
    BFormFile,
    BCardTitle,
    BFormInput,
    BFormGroup,
    AppCollapse,
    BInputGroup,
    BCardHeader,
    BButtonGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    AppCollapseItem,
    ToastificationContent,
    AsignarCrearCategorias,
    ModalImagenesCategorias,
    SidebarDescuentosCategorias,
  },
  directives: {
    Ripple,
  },
  props: {
    tipo: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    subCategorias: {
      type: Array,
      required: true,
    },
    categoriaSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      bodegas: [],
      loading: false,
      sectores: [],
      imageSRC: null,
      categoria: null,
      showLoader: false,
      loadingImage: false,
      categoriasHija: [],
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
    };
  },
  beforeMount() {
    this.categoria = this.categoriaSelected;
    this.categoriasHija = this.subCategorias.filter(
      (c) => c.lineaDeNegocio === this.tipo
    );
  },
  watch: {
    categoriaSelected: function (newVal) {
      if (newVal !== null) this.categoria = newVal;
    },
  },
  methods: {
    onImageChange(url, tipo, id) {
      this.$emit("onchange", url, tipo, id);
    },
    editSubCategory(cat) {
      this.$refs["crearEditar"].editar(cat);
    },
    setShowLoader(show) {
      this.showLoader = show;
    },
    deleteConfirm(cat) {
      this.$bvModal
        .msgBoxConfirm(`Desea eliminar la subcategoria [${cat.nombre}].`, {
          title: "Eliminar subcategoria",
          size: "sm",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deleteAssign(cat.id);
        });
    },
    deleteAssign(childID) {
      this.showLoader = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Categoria/CategoriaPadresAdmin",
          body: JSON.stringify({
            opt: "D",
            IDEmpresa: 2,
            tokenSesion: getAuthToken(),
            IDCategoriaHija: childID,
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            this.showLoader = false;
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se actualizo correctamente la categoria.",
                  title: "Exito",
                  variant: "success",
                },
              });
              return;
            }
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: res.data.mensaje,
              title: "Error",
              variant: "danger",
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader = false;
        });
    },
    savedata() {
      this.showLoader = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategory",
          body: JSON.stringify({
            empresa: 2,
            categoria: {
              id: this.categoria.id,
              tipo: this.generateType,
              nombre: this.categoria.nombre,
              activa: this.categoria.activo,
              idPadre: 0,
            },
            tokenSesion: getAuthToken(),
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            const resp = await this.callback(
              this.tipo,
              this.tipo === "MARKET" ? 0 : 1,
              false
            );
            // this.rebuildCache();
            this.showLoader = false;
            if (resp === "") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se actualizo correctamente la categoria.",
                  title: "Exito",
                  variant: "success",
                },
              });
              return;
            }
          }
          this.showLoader = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: res.data.mensaje,
              title: "Error",
              variant: "danger",
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
          this.showLoader = false;
        });
    },
    onChangeOrder(event) {
      const catIDs = this.categoria.hijas.map((c) => parseInt(c.id));
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetCategoriesOrder",
          body: JSON.stringify({
            sort: catIDs,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se actualizo correctamente el orden de las subcategorias.",
                title: "Exito",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: res.data.mensaje,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    async rebuildCache() {
      const keys = [];
      await new Promise((resolve, reject) =>
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              this.sectores = res.data.lssectores;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          })
      );
      if (this.tipo === "MARKET") {
        mainHomeRequests.forEach((key) => keys.push(key));
        this.bodegas.forEach((b) => {
          keys.push({
            path: "/Customer/AppHome",
            body: { tipo: this.tipo, bodega: b.id },
          });
          keys.push({
            path: "/Productos/ConsultaCategoriasRewok",
            body: {
              Tipo: "C",
              Tienda: false,
              IDBodega: b.id,
              IDEmpresa: 2,
              TipoComercio: this.tipo,
            },
          });
        });
      } else
        this.sectores.forEach((s) => {
          keys.push({
            path: "/Customer/AppHome",
            body: { tipo: this.tipo, sector: s.id },
          });
          keys.push({
            path: "/Productos/ConsultaCategoriasRewok",
            body: {
              Tipo: "C",
              Tienda: true,
              IDSector: s.id,
              IDEmpresa: 2,
              tokenSesion: getAuthToken(),
              TipoComercio: this.tipo,
            },
          });
        });
      keys.forEach((key) => {
        key.body.refreshCache = true;
        this.$http.post(this.$store.state.app.nodeMiddleware, key);
      });
    },
  },
  computed: {
    generateType() {
      let tipo = "";
      if (this.categoria.showHome) tipo += "H";
      if (this.categoria.showMain) tipo += "M";
      if (this.categoria.showCategorias) tipo += "C";
      if (this.categoria.showDestacadas) tipo += "D";
      if (tipo.length === 0) tipo += "N";
      return tipo;
    },
    tipoComercio() {
      switch (this.tipo) {
        case "EATS":
          return "Foodie";
        case "MARKET":
          return "Market";
        case "OTROS_COMERCIOS":
          return "Plus";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
.dpf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mdm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>